// The configuration in this file are used for the running the app on prod mode

export const environment = {
  hmr: false,
  development: false,
  staging: false,
  production: true,
  api: 'https://api.civis.vote',
  RECAPTCHA_SITE_KEY: '6Ld8GLUUAAAAAH5CZbqDdQDwl-s5ZC2ZqHz5TWyj'
};

